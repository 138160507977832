.App {
  text-align: center;
}

.App-header {
  background: linear-gradient(90deg, #040404 20px, transparent 1%) center, linear-gradient(#040404 20px, transparent 1%) center, #fa4454;
  background-size: 21px 21px;
  overflow-x: hidden;
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.PlayButton {
  color: rgb(255, 255, 255);
  border-radius: 4px;
  font-size: 60px;
  z-index: 3;
  background: transparent;
  border: 2px solid rgb(221, 221, 221);
  padding: 56px 210px;
}

.OptionButton {
  display: inline-block;
  appearance: none;
  font-weight: 900;
  max-width: 100%;
  font-size: 16px;
  text-transform: uppercase;
  text-align: left;
  line-height: 1.2;
  min-width: 60px;
  position: relative;
  color: rgb(255, 255, 255);
  border-radius: 4px;
  margin-top: 20px;
  margin-left: 36px;
  margin-right: 36px;
  z-index: 3;
  letter-spacing: 1px;
  background: transparent;
  border: 2px solid rgb(221, 221, 221);
  padding: 96px 116px;
}
